const ITEM_DETAILS_ADD_DATA_TO_TABLE = "ITEM_DETAILS_ADD_DATA_TO_TABLE";
const ITEM_DETAILS_DELETE_DATA_FROM_ADD_DATA =
  "ITEM_DETAILS_DELETE_DATA_FROM_ADD_DATA";
const ITEM_DETAILS_DELETE_ALL_ADD_DATA = "ITEM_DETAILS_DELETE_ALL_ADD_DATA";
const ITEM_DETAILS_ADD_DATA_REQUEST = "ITEM_DETAILS_ADD_DATA_REQUEST";
const ITEM_DETAILS_MODAL_CONFIRMATION = "ITEM_DETAILS_MODAL_CONFIRMATION";
const ITEM_DETAILS_ADD_DATA_SHOW_SNACKBAR =
  "ITEM_DETAILS_ADD_DATA_SHOW_SNACKBAR";
const ITEM_DETAILS_ADDED_LAST_DATA = "ITEM_DETAILS_ADDED_LAST_DATA";
const ITEM_DETAILS_DELETE_ADD_DATA_RESPONSE =
  "ITEM_DETAILS_DELETE_ADD_DATA_RESPONSE";

const ITEM_DETAILS_OWNER_ID = "ITEM_DETAILS_OWNER_ID";
const ITEM_DETAILS_OWNERS_DATA = "ITEM_DETAILS_OWNERS_DATA";
const ITEM_DETAILS_ADD_DATA_MODAL_CONFIRMATION =
  "ITEM_DETAILS_ADD_DATA_MODAL_CONFIRMATION";

const ITEM_DETALS_ADD_STATUS_FIELD = "ITEM_DETALS_ADD_STATUS_FIELD";
const ITEM_DETAILS_SHOW_ADD_DATA_STATUS_TABLE =
  "ITEM_DETAILS_SHOW_ADD_DATA_STATUS_TABLE";
const ITEM_DETAILS_SHOW_TRANSFER_SPINNER = "ITEM_DETAILS_SHOW_TRANSFER_SPINNER";
const SHARE_MODAL_CONFIRMATION = "SHARE_MODAL_CONFIRMATION";

const CLEAR_ITEM_DETAILS_STATE = "CLEAR_ITEM_DETAILS_STATE";
const CLEAR_TRANSFER_STATE = "CLEAR_TRANSFER_STATE";
const SCRIPT_LOADED = "SCRIPT_LOADED";

const CLEAR_ADD_DATA = "CLEAR_ADD_DATA";
const ADD_SFC_DATA = "ADD_SFC_DATA";

const DELETE_ALL_SFC_INPUTS = "DELETE_ALL_SFC_INPUTS";
const CHANGE_SFC_INPUTS_STATUS = "CHANGE_SFC_INPUTS_STATUS";
const CHANGE_PRODUCT_DATA_STATUS = "CHANGE_PRODUCT_DATA_STATUS";

const TAG_SENSOR_INPUT = "TAG_SENSOR_INPUT";
const TAG_SENSOR_ID_INPUT = "TAG_SENSOR_ID_INPUT";
const SHOW_SUBSCRIBE_MODAL = "SHOW_SUBSCRIBE_MODAL";
const ITEM_DETAILS_ADD_FILE_UPLOAD = "ITEM_DETAILS_ADD_FILE_UPLOAD";
const CLEAR_FILE_UPLOAD_STATE = "CLEAR_FILE_UPLOAD_STATE";
const SAVE_TAB = "SAVE_TAB";
const CLEAR_DOWNLOAD = "CLEAR_DOWNLOAD";

const HIDE_TWIN_PICTURES = "HIDE_TWIN_PICTURES";
const STORE_GROUPS = "STORE_GROUPS";

const ALLOW_SHARING = "ALLOW_SHARING";
const HIDE_FOOD_FORM = "HIDE_FOOD_FORM";

export default {
  ITEM_DETAILS_ADD_DATA_TO_TABLE,
  ITEM_DETAILS_DELETE_DATA_FROM_ADD_DATA,
  ITEM_DETAILS_DELETE_ALL_ADD_DATA,
  ITEM_DETAILS_MODAL_CONFIRMATION,
  ITEM_DETAILS_ADD_DATA_REQUEST,
  ITEM_DETAILS_OWNER_ID,
  ITEM_DETAILS_OWNERS_DATA,
  ITEM_DETAILS_ADD_DATA_MODAL_CONFIRMATION,
  ITEM_DETAILS_ADD_DATA_SHOW_SNACKBAR,
  ITEM_DETAILS_DELETE_ADD_DATA_RESPONSE,
  ITEM_DETALS_ADD_STATUS_FIELD,
  ITEM_DETAILS_SHOW_ADD_DATA_STATUS_TABLE,
  ITEM_DETAILS_ADDED_LAST_DATA,
  ITEM_DETAILS_SHOW_TRANSFER_SPINNER,
  SHARE_MODAL_CONFIRMATION,
  CLEAR_ITEM_DETAILS_STATE,
  CLEAR_TRANSFER_STATE,
  SCRIPT_LOADED,
  CLEAR_ADD_DATA,
  ADD_SFC_DATA,
  DELETE_ALL_SFC_INPUTS,
  CHANGE_SFC_INPUTS_STATUS,
  CHANGE_PRODUCT_DATA_STATUS,
  TAG_SENSOR_INPUT,
  TAG_SENSOR_ID_INPUT,
  SHOW_SUBSCRIBE_MODAL,
  ITEM_DETAILS_ADD_FILE_UPLOAD,
  CLEAR_FILE_UPLOAD_STATE,
  SAVE_TAB,
  CLEAR_DOWNLOAD,
  HIDE_TWIN_PICTURES,
  STORE_GROUPS,
  ALLOW_SHARING,
  HIDE_FOOD_FORM,
};
